<template>
    <div>
        <!-- CAROUSEL-->
    <b-row>
      <b-col cols="12" >
        <div>
          <b-carousel indicators :interval="3000" controls >
            <b-carousel-slide       
                v-if="resources!=null"         
                :img-src="resources.img_header_principal"
            />
          </b-carousel>
        </div>
      </b-col>
    </b-row>
    
        <br>
<h1 class="titles mt-3">
          <span class="c-shadow-sm" style="color: #8b8c8c; padding:15px;">Conoce Teotihuacán</span>
        </h1>
<br>
        <!-- <h1 class="titles">
          <span style="color: #d82125">M</span>
          <span style="color: #d02175">u</span>
          <span style="color: #f6c921">n</span>
          <span style="color: #d02175">i</span>
          <span style="color: #4d944a">c</span>
          <span style="color: #07aaba">i</span>
          <span style="color: #d82125">p</span>
          <span style="color: #d02175">i</span>
          <span style="color: #f6c921">o</span>
        </h1> -->
        
        <b-row  align-h="center" class="mt-4" >
      <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
        
          <b-container   style="cursor:pointer;">
            <a href="https://turismo.teotihuacan.gob.mx/hoteles" target="_blank">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/hotelp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
            </a>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">¿ Dónde hospedarse ?</span> </h2>
           <br><br><br><br>
        </b-col>

        <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container  style="cursor:pointer;">
            <a href="https://turismo.teotihuacan.gob.mx/restaurantes" target="_blank">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/restaurantep.jpg" fluid rounded class="c-shadow-sm" ></b-img>
            </a>
          </b-container>
          <h2 class="titles "> <span style="color: #d82125">¿ Dónde comer ?</span> </h2>
          <br><br><br><br>
        </b-col>


          <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container  style="cursor:pointer;">
            <a href="https://turismo.teotihuacan.gob.mx/atracciones" target="_blank">
              <b-img src="https://www.teotihuacan.gob.mx/turismot/globosp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
            </a>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">Atracciones para divertirse</span> </h2>         
           <br><br><br><br>
        </b-col>

         <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container  style="cursor:pointer;">
            <a href="https://turismo.teotihuacan.gob.mx/temazcales" target="_blank">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/temazcalp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
            </a>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">Experiencia de Salud y Descanso</span> </h2>         
          <br><br><br><br>
        </b-col>
       


  <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container  style="cursor:pointer;">
            <a href="https://turismo.teotihuacan.gob.mx/fiestas" target="_blank">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/fiestasp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
            </a>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">Fiestas para disfrutar</span> </h2>         
            <br><br><br><br>
        </b-col>

   <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container  style="cursor:pointer;">
            <a href="https://turismo.teotihuacan.gob.mx/danzantes" target="_blank">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/danzap.jpg" fluid rounded class="c-shadow-sm" ></b-img>
            </a>
          </b-container>
           <h2 class="titles "> <span style="color: #d82125">Juego de Pelota y Danza Prehispánica</span> </h2>         
           <br><br><br><br>
        </b-col>


 <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container style="cursor:pointer;">
            <a href="https://turismo.teotihuacan.gob.mx/monumentos" target="_blank">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/monumentop.jpg" fluid rounded class="c-shadow-sm" ></b-img>
            </a>
          </b-container>
          <h2 class="titles "> <span style="color: #d82125">Monumentos Hístoricos</span> </h2>         
          <br><br><br><br>
        </b-col>

 <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
   <b-container  style="cursor:pointer;">
     <a href="https://turismo.teotihuacan.gob.mx/zona" target="_blank">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/teotihuacanp.jpg" fluid rounded class="c-shadow-sm" ></b-img>
     </a>
          </b-container>
            <h2 class="titles "> <span style="color: #d82125">Zona Arquelógica</span> </h2>         
            <br><br><br><br>
        </b-col>

  <b-col cols="10" sm="8" md="7" lg="6"  class="mb-3">
          <b-container style="cursor:pointer;">
            <a href="https://turismo.teotihuacan.gob.mx/artesanias" target="_blank">
            <b-img src="https://www.teotihuacan.gob.mx/turismot/artesaniap.jpg" fluid rounded class="c-shadow-sm" ></b-img>
            </a>
          </b-container>
            <h2 class="titles "> <span style="color: #d82125">Venta de Artesanías</span> </h2>         
            <br><br><br><br>
        </b-col>





    </b-row>

        <div>
            
        </div>
    </div>
</template>
<script>
  import axios from "axios";
  export default {
    components: {
    },
    mounted: function(){
      var host = location.protocol + '//' + location.host;
      axios({
          method: 'get',
          url: host+'/files/municipio.json',
      })
      .then(res => {
          this.resources = res.data;
      }).catch(err => {
        alert(err);  
      });
    },
    computed: {
      
    },
    data() {
      return {
        resources:null,
      }
    }
    
  }
</script>